<template>
    <div class="calculator">
        <el-card class="box-card">
            <el-form :inline="true" label-position="top" class="inline-form">
                <el-form-item label="van">
                    <el-select v-model="selected_from" filterable placeholder="Select">
                        <el-option-group v-for="group in mathOptions" :key="group.label" :label="group.label">
                            <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                                <span style="float: left">{{ item.label }}</span>
                                <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px">{{ item.value }}</span>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>

                <el-form-item label="naar">
                    <el-select v-model="selected_to" filterable placeholder="Select">
                        <el-option-group v-for="group in mathOptions" :key="group.label" :label="group.label">
                            <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                                <span style="float: left">{{ item.label }}</span>
                                <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px">{{ item.value }}</span>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form>
                <el-input v-model="input" placeholder="van">
                    <template #append>{{ selected_from }}</template>
                </el-input>
                <h2 class="seperator">=</h2>
                <el-input v-model="answer" placeholder="antwoord">
                    <template #append>{{ selected_to }}</template>
                </el-input>
                <br /><br />
                <el-button type="primary" @click="calculate">Berekenen</el-button>
                <el-button @click="switchValues">omdraaien</el-button>
                <el-button type="text" @click="openInfoModal">meer info</el-button>
                <p v-if="error" class="error">{{ error }}</p>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { ElMessageBox } from 'element-plus';

var convert = require('convert-units');

export default {
    setup(props, context) {
        const mathOptions = ref([]);

        const selected_from = ref(null);
        const selected_to = ref(null);

        const input = ref(null);
        const answer = ref(null);

        const error = ref('');

        const store = useStore();

        function createOptions() {
            const measures = convert().measures();

            measures.forEach((measure) => {
                // For each measure create a new object with an array
                let newObject = {
                    value: measure,
                    label: measure,
                    options: [],
                };

                convert()
                    .list(measure)
                    .forEach((option) => {
                        newObject.options.push({
                            value: option.abbr,
                            label: `${option.singular} (${option.abbr}) `,
                        });
                    });

                mathOptions.value.push(newObject);
            });
        }

        function switchValues() {
            const fromTemp = selected_from.value;
            console.log(selected_from.value, selected_to.value);
            selected_from.value = selected_to.value;
            selected_to.value = fromTemp;
        }

        function calculate() {
            if (selected_from.value != null && selected_to.value != null && input.value != null) {
                try {
                    error.value = '';
                    const calcAnswer = convert(+input.value)
                        .from(selected_from.value)
                        .to(selected_to.value);
                    answer.value = calcAnswer;

                    addToLocalStorage({
                        from: selected_from.value,
                        to: selected_to.value,
                        input: input.value,
                        answer: calcAnswer,
                    });

                    store.commit('addToHistory', {
                        from: selected_from.value,
                        to: selected_to.value,
                        input: input.value,
                        answer: calcAnswer,
                    });
                } catch (err) {
                    console.log(err);
                    error.value = 'Deze berekening is niet mogelijk!';
                }
            } else {
                error.value = 'Vul alle mogelijke velden in!';
            }
        }

        function openInfoModal() {
            ElMessageBox.alert('Alle getalen moeten aan elkaar worden geschreven, voorbeeld: 12345,67. Je laatst uitgevoerde berekeningen worden opgeslagen in je localstorage. Deze verloopt nadat je je browser hebt afgesloten.', 'Meer info', {
                confirmButtonText: 'OK',
            });
        }

        function addToLocalStorage(object) {
            const currentLocalStorage = JSON.parse(localStorage.getItem('calculations')) || [];
            currentLocalStorage.push(object);
            localStorage.setItem('calculations', JSON.stringify(currentLocalStorage));
        }

        onMounted(() => {
            createOptions();
        });

        return {
            mathOptions,
            selected_from,
            selected_to,
            input,
            answer,
            error,
            switchValues,
            calculate,
            openInfoModal,
        };
    },
};
</script>
<style lang="scss" scoped>
.calculator {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
}
.inline-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.switch-icon {
    margin-top: 25px;
    width: 35px;
}
.seperator {
    text-align: center;
    font-size: 35px;
}
.error {
    margin-top: 5px;
    color: red;
}
.el-form--inline .el-form-item {
    margin-right: 0;
}
</style>
