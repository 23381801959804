<template>
    <div class="header">
        <h1 class="title">Omrekenen</h1>
        <a href="https://gerbenkr.xyz" target="_blank" class="author">By GerbenKr.xyz</a>
    </div>
</template>
<style lang="scss">
.header {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
        font-size: 40px;
    }
    .author {
        margin-top: -10px;
        font-size: 15px;
    }
}
</style>
