<template>
    <div class="history">
        <el-card class="box-card">
            <template #header>
                <div class="card-header">
                    <span>Laatst uitgevoerde berekeningen</span>
                </div>
            </template>
            <div v-if="history">
                <div v-for="(item, index) in history" :key="index" class="text item">{{ item.input }} {{ item.from }} = {{ item.answer }} {{ item.to }}</div>
            </div>
            <div v-else>
                <div class="text item">Geen recente berekeningen gevonden..</div>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    computed: {
        history: function () {
            return this.$store.getters.getHistory;
        },
    },
    mounted() {
        this.$store.commit('setHistory', JSON.parse(localStorage.getItem('calculations')) || null);
    },
};
</script>
<style lang="scss" scoped>
.history {
    max-width: 480px;
    width: 100%;
    margin: 25px auto;
    padding: 10px;
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}
</style>
