<template>
    <div class="over">
        <el-card class="box-card">
            <template #header>
                <div class="card-header">
                    <span>Over</span>
                </div>
            </template>
            <div class="text">
                <p>Deze simpele tool is gemaakt door <a href="https://gerbenkr.xyz" target="_blank">GerbenKr</a> met behulp van Vue, Element Plus en convert-units.</p>
                <p>Deze website is een PWA app en werkt dus ook offline.</p>
            </div>
            <!-- <div v-if="history.length > 0">
                <div v-for="(item, index) in history" :key="index" class="text item">{{ item.input }} {{ item.from }} = {{ item.answer }} {{ item.to }}</div>
            </div> -->
        </el-card>
    </div>
</template>
<style lang="scss" scoped>
.over {
    max-width: 480px;
    width: 100%;
    margin: 25px auto;
    padding: 10px;
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}
</style>
