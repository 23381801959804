<template>
    <div>
        <Header />
        <Calculator />
        <History />
        <Over />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Calculator from '@/components/Calculator.vue';
import History from '@/components/History.vue';
import Over from '@/components/Over.vue';

export default {
    components: {
        Header,
        Calculator,
        History,
        Over,
    },
};
</script>
