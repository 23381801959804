import { createStore } from 'vuex';

export default createStore({
    state: {
        history: [],
    },
    mutations: {
        setHistory(state, object) {
            state.history = object;
        },
        addToHistory(state, object) {
            if (state.history) {
                state.history.push(object);
            } else {
                state.history = object;
            }
        },
    },
    getters: {
        getHistory(state) {
            if (state.history) {
                return state.history;
            } else {
                return [];
            }
        },
    },
    actions: {},
    modules: {},
});
